@import 'styles/base.scss';

.base {
  width: 450px;
  border-radius: 16px;

  @include for-size(mobile) {
    width: 100%;
  }
}


.iconsBar {

}

.header {
  padding: 0 24px;

}

.body {
  padding: 0 24px;

  .input {
    padding-left: 12px;
  }

  .inputWrapper {
    height: 51px !important;
    border-radius: 12px;
    border: solid 1px transparent !important;
    background-color: map-get($colors, grey-1) !important;
  }
}

.footer {
  padding: 24px;
  margin-top: 0px;

  button {
    border-radius: 12px;
  }
}

@import 'styles/base.scss';

.base {
  width: 100%;
  overflow: hidden;
  text-align: left;
  position: relative;

  &.full {
    border-radius: 2px;
    border-bottom: 0px;

    .inputIcon {
      margin-left: 16px;
    }

    .wrapper {
      background: map-get($colors, white);
    }
  }

  &.valid {
    .wrapper {
      border-color: map-get($colors, lush);
    }

    .validationIcon,
    .validationText {
      color: map-get($colors, lush);
    }

    .icons {
    }
  }

  &.invalid {
    .wrapper {
      border-color: map-get($colors, error);
    }

    .validationIcon,
    .validationText {
      color: map-get($colors, error);
    }

    .icons {
    }
  }

  &.withButton {
    .icons > :last-child {
      margin-right: 16px;
    }
  }
}

.input {
  @include font($size: 16px, $color: midnight, $lineHeight: normal, $weight: medium);
  width: 100%;
  height: 50px;
  padding-right: 15px;
  border: 0px;
  margin: 0px;
  outline: none;
  background: transparent;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: map-get($colors, midnight);
    -webkit-box-shadow: 0 0 0 50px map-get($colors, white) inset;
    overflow: hidden;
    height: 44px;
    margin-top: 1px;
    @include font($size: 16px, $color: midnight, $weight: medium);

    & + .icons {
      background: white;
    }
  }

  &[readonly],
  &[disabled] {
    opacity: 0.4;
    cursor: default;
  }

  @include placeholder {
    @include font($size: 16px, $color: grey-4, $weight: medium);
  }
}

.textarea {
  @include font($size: 16px, $color: midnight, $lineHeight: normal, $weight: medium);
  width: 100%;
  height: 100%;
  min-height: 130px;
  padding: 15px;
  margin: 0px;
  resize: none;
  outline: none;
  background-color: transparent;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  border: none;

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: map-get($colors, midnight);
    -webkit-box-shadow: 0 0 0 50px map-get($colors, white) inset;
    overflow: hidden;
    height: 44px;
    margin-top: 1px;
    @include font($size: 16px, $color: midnight, $weight: medium);

    & + .icons {
      background: white;
    }
  }

  &[readonly],
  &[disabled] {
    opacity: 0.4;
    cursor: default;
  }

  &Rows {
    min-height: unset;
  }

  @include placeholder {
    @include font($size: 16px, $color: grey-4, $weight: medium);
  }
}

.label {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 26px;
  user-select: none;
  @include font($size: 14px, $color: grey-5);
}

.wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid map-get($colors, grey-3);
}

.wrapperMultiline {
  min-height: 50px;
  height: auto;
  border: 1px solid map-get($colors, grey-3);
  border-radius: 4px;
}

.inputIcon {
  position: relative;
  bottom: 0px;
  line-height: 50px;
  font-size: 22px;
  width: 20px;
  margin-right: 5px;
  margin-left: 8px;
  height: 50px;
  text-align: left;
  color: map-get($colors, midnight);
}

.icons {
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 100%;
  line-height: 46px;
  font-size: 18px;
  border-bottom: 1px solid transparent;

  > :last-child {
    margin-right: 5px;
  }
}

.inputButton {
  width: auto;
  min-width: inherit;
  overflow: visible;
  height: 49px;
  line-height: 35px;
  margin-right: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.icon {
  position: relative;
  margin-right: 10px;
  display: inline;
}

.loaderIcon {
  color: map-get($colors, grey-3);
}

.clearableIcon {
  color: map-get($colors, grey-3);

  &:hover {
    color: map-get($colors, midnight);
    cursor: pointer;
  }
}

.hideIcon {
  top: 4px;
  font-size: 27px;

  &:hover {
    color: map-get($colors, lush);
    cursor: pointer;
  }
}

.validationText {
  min-height: 17px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  @include font($size: 14px, $lineHeight: 17px);
}

.subText {
  @include font($size: 14px, $color: grey-4);
  margin-top: 6px;
}

.theme {
  &-corporate {
    &.valid {
      .wrapper {
        border-color: map-get($colors, teal);
      }

      .validationIcon,
      .validationText {
        color: map-get($colors, teal);
      }
    }

    .hideIcon:hover {
      color: map-get($colors, teal);
    }
  }

  &-medical {
    &.valid {
      .wrapper {
        border-color: map-get($colors, dusty-blue);
      }

      .validationIcon,
      .validationText {
        color: map-get($colors, dusty-blue);
      }
    }

    .hideIcon:hover {
      color: map-get($colors, dusty-blue);
    }
  }

  &-health {
    &.valid {
      .wrapper {
        border-color: map-get($colors, health);
      }

      .validationIcon,
      .validationText {
        color: map-get($colors, health);
      }
    }

    .hideIcon:hover {
      color: map-get($colors, health);
    }
  }
}

.passwordStrengthWrapper {
  height: 0px;
  overflow: hidden;
  transition: all 0.5s ease;
  opacity: 0;

  &--visible {
    height: 150px;
    opacity: 1;
  }
}

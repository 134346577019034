@import 'styles/base.scss';

.topper {
  margin-top: 0px;
}

.link {
  margin: 8px 0;
}

.applePayButton {
  display: block;
  width: 100%;
  margin: 16px 0;
}

.sdk-form-loader {
  position: absolute;
  color: map-get($colors, grey-4);
  left: calc(50% - 12px);
  top: calc(50% - 9px);
  z-index: 99;
  font-size: 40px !important;
}

.modal {
  border-radius: 16px;

  &-iconsBar {

  }

  &-header {
    &--hidden {
      display: none;
    }
  }

  &-body {
    padding: 0 24px;
  }

  &-footer {
    padding: 0px 24px 24px;


    button {
      border-radius: 12px;
    }

    &--tight {
      margin-top: 0px;

    }
  }

  &--fixedLoading {
    height: 250px;
    min-height: 250px;
    max-height: 250px;
    overflow: hidden;
  }
}
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

*,
*:before,
*:after {
    box-sizing: border-box;
}

:root {
    interpolate-size: allow-keywords;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-weight: 400;
}

html {
    overflow-x: hidden;
}

ul,
ol {
    list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    font-family: 'HKGrotesk', Arial, sans-serif;
    color: #333;
    overflow: auto;
}

h1,
h2,
h3,
h4,
h5 h6 {
    font-family: 'DomaineDisplay', Arial, sans-serif;
    color: #333;
}

a {
    text-decoration: none;
}

@font-face {
    font-family: 'DomaineDisplay';
    src: url('https://assets.zeel.com/web/fonts/DomaineDisplay-Regular.otf');
}

@font-face {
    font-family: 'DomaineDisplayItalic';
    src: url('https://assets.zeel.com/web/fonts/DomaineDisplay-RegularItalic.otf');
}

@font-face {
    font-family: 'CustomIconsVF';
    src: url('~assets/fonts/CustomIcons.ttf') format('truetype');
}

@font-face {
    font-family: 'HKGrotesk-SemiBold';
    src: url('https://assets.zeel.com/web/fonts/HKGrotesk-SemiBold.woff') format('woff'),
    url('https://assets.zeel.com/web/fonts/HKGrotesk-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HKGrotesk-Bold';
    src: url('https://assets.zeel.com/web/fonts/HKGrotesk-Bold.woff') format('woff'),
    url('https://assets.zeel.com/web/fonts/HKGrotesk-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HKGrotesk';
    src: url('https://assets.zeel.com/web/fonts/HKGrotesk-Regular.woff') format('woff'),
    url('https://assets.zeel.com/web/fonts/HKGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HKGrotesk-Medium';
    src: url('https://assets.zeel.com/web/fonts/HKGrotesk-Medium.woff') format('woff'),
    url('https://assets.zeel.com/web/fonts/HKGrotesk-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HKGrotesk-Light';
    src: url('https://assets.zeel.com/web/fonts/HKGrotesk-Light.woff') format('woff'),
    url('https://assets.zeel.com/web/fonts/HKGrotesk-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bradley-Hand-Bold';
    src: url('https://assets.zeel.com/web/fonts/Bradley-Hand-Bold.ttf');
}
